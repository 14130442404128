import React, { useEffect, useRef, useState } from "react";

const useFocus = () => {
  const htmlElRef = useRef(null);
  const setFocus = () => {
    htmlElRef.current && htmlElRef.current.focus();
  };

  return [htmlElRef, setFocus];
};

const PhoneField = ({ input }) => {
  const [inputRef1, setInput1Focus] = useFocus();
  const [inputRef2, setInput2Focus] = useFocus();
  const [phoneNumber, setPhoneNumber] = useState({});
  const [val1, setVal1] = useState(null);
  const [val2, setVal2] = useState(null);
  const [val3, setVal3] = useState(null);

  useEffect(() => {
    const val = input.value;
    if (val?.length === 10) {
      const first = val.substr(0, 3);
      const second = val.substr(3, 3);
      const third = val.substr(6, 4);
      setVal1(first);
      setVal2(second);
      setVal3(third);
      setPhoneNumber({ first, second, third });
    }
  }, []);

  useEffect(() => {
    const res =
      (phoneNumber.first || "") +
      (phoneNumber.second || "") +
      (phoneNumber.third || "");
    input.onChange(res);
  }, [phoneNumber]);

  const valueChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;

    // const value = e.target.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1')

    if (name === "first") {
      if (value && value.length === 3) {
        setInput1Focus();
      }
    }
    if (name === "second") {
      if (value && value.length === 3) {
        setInput2Focus();
      }
    }
    setPhoneNumber((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const isNumber = (value) => {
    if (value.key === "Backspace") {
      return true;
    } else {
      return !/^[0-9]*$/.test(value.key) && value.preventDefault();
    }
  };

  return (
    <div>
      <input
        autoComplete={input.name === "homephone" ? "new-value" : ""}
        type="text"
        defaultValue={val1}
        style={{ maxWidth: "40px" }}
        onInput={valueChange}
        maxLength={3}
        name={"first"}
        aria-label="first"
        onKeyDown={(evt) => {
          isNumber(evt);
        }}
      />
      <input
        autoComplete={input.name === "homephone" ? "new-value" : ""}
        type="text"
        defaultValue={val2}
        style={{ maxWidth: "40px" }}
        onInput={valueChange}
        ref={inputRef1}
        name={"second"}
        aria-label="second"
        className="ms-3"
        maxLength={3}
        onKeyDown={(evt) => {
          isNumber(evt);
        }}
      />
      <input
        autoComplete={input.name === "homephone" ? "new-value" : ""}
        type="text"
        defaultValue={val3}
        style={{ maxWidth: "80px" }}
        className="ms-3"
        onInput={valueChange}
        ref={inputRef2}
        name={"third"}
        aria-label="third"
        maxLength={4}
        onKeyDown={(evt) => {
          isNumber(evt);
        }}
      />
    </div>
  );
};
export default PhoneField;
