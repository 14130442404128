import React from "react";
import Seo from "../components/seo/seo";

import ReserveForm from "../components/reserve-form/ReserveForm";
import BubblesBgHeader from "../shared/bubbles-bg-header/BubblesBgHeader";

const ReservePage = () => {
  return (
    <div className="register-page">
      <Seo title="Reserve - " />
      <BubblesBgHeader text="Reserve" />
      <div className="content-block container mt-5">
        <ReserveForm />
      </div>
    </div>
  );
};

export default ReservePage;
