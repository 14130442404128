import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { faCircle } from "@fortawesome/free-regular-svg-icons";

const SlotOption = ({ slot, session, setSession, isSelected }) => {
  const isNotAvailableSlot = (slot) => {
    return slot.registered >= slot.maxStudent || slot.maxStudent === 0;
  };

  if (isSelected) {
    return (
      <div
        className="cursor-pointer mb-2 time-slot"
        key={slot.id}
        onClick={() => {
          slot.isSelected = false;
          setSession({ ...session });
        }}
      >
        <span className="">
          <FontAwesomeIcon
            className={"me-2 text-primary"}
            icon={faCheckCircle}
          />
          {slot.fromTimeResolved
            ? `${slot.fromTimeResolved} - ${slot.toTimeResolved}`
            : slot.notes}
        </span>
        {slot.fromTimeResolved && (
          <span className="text-red">
            {slot.notes ? ` (${slot.notes})` : ""}
          </span>
        )}
      </div>
    );
  } else {
    return (
      <div
        className="cursor-pointer time-slot"
        key={slot.id}
        onClick={() => {
          if (isNotAvailableSlot(slot)) {
            return;
          }

          slot.isSelected = true;
          setSession({ ...session });
        }}
      >
        <span className={"" + (isNotAvailableSlot(slot) ? "disable-slot" : "")}>
          <FontAwesomeIcon className={"me-2 text-primary"} icon={faCircle} />
          {slot.fromTimeResolved
            ? `${slot.fromTimeResolved} - ${slot.toTimeResolved}`
            : slot.notes}
        </span>
        <span className="text-red">
          {isNotAvailableSlot(slot) ? (
            <span className="class-full-text">&nbsp;(Class Full)</span>
          ) : slot.notes && slot.fromTimeResolved ? (
            ` (${slot.notes})`
          ) : (
            ""
          )}
        </span>
      </div>
    );
  }
};

export default SlotOption;
