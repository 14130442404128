import React, { useEffect, useState } from "react";
import axios from "../../../../http/axios";
import moment from "moment";

import { faCircle } from "@fortawesome/free-regular-svg-icons";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { store, useGlobalState } from "state-pool";
import SlotOption from "./SlotOption";

const SessionField = (props) => {
  const [session1, setSession1] = useState(null);
  const [session2, setSession2] = useState(null);
  const [session3, setSession3] = useState(null);
  let [sessions, setSessions] = useState([]);

  const [sessionError1, setSessionError1, updateSessionError1] =
    useGlobalState("sessionError1");

  const [sessionError2, setSessionError2, updateSessionError2] =
    useGlobalState("sessionError2");

  const [sessionError3, setSessionError3, updateSessionError3] =
    useGlobalState("sessionError3");

  let sessionChangeCounter = 0;

  useEffect(() => {
    if (!sessions?.length && props.sessions?.length) {
      setSessions(props.sessions);
    }
  }, []);

  useEffect(() => {
    setSessions(props.sessions);
  }, [props.sessions]);

  useEffect(() => {
    let initialSessionsValues = props.input.value;

    if (initialSessionsValues?.length) {
      let pickedFirstSession;
      let pickedSecondSession;
      let pickedThirdSession;

      let theFirstSession = initialSessionsValues[0];
      let theSecondSession;
      let theThirdSession;

      for (let i = 0; i < initialSessionsValues.length; ++i) {
        if (initialSessionsValues[i].id !== theFirstSession.id) {
          if (
            theSecondSession &&
            initialSessionsValues[i].id !== theSecondSession.id
          ) {
            theThirdSession = initialSessionsValues[i];
          } else {
            theSecondSession = initialSessionsValues[i];
          }
        }
      }

      let firsSessionSlots = [];
      let secondSessionSlots = [];
      let thirdSessionSlots = [];
      initialSessionsValues.forEach((slot) => {
        if (slot.id === theFirstSession.id) {
          firsSessionSlots.push(slot);
        }
        if (theSecondSession && slot.id === theSecondSession.id) {
          secondSessionSlots.push(slot);
        }
        if (theThirdSession && slot.id === theThirdSession.id) {
          thirdSessionSlots.push(slot);
        }
      });

      initialSessionsValues = [
        firsSessionSlots,
        secondSessionSlots,
        thirdSessionSlots,
      ];

      initialSessionsValues.forEach((item) => {
        if (item === undefined || item.length === 0) {
          initialSessionsValues.splice(initialSessionsValues.indexOf(item), 1);
        }
      });

      if (Array.isArray(initialSessionsValues[0])) {
        pickedFirstSession = sessions.filter(
          (session) => initialSessionsValues[0][0].id === session.id
        );
      } else {
        pickedFirstSession = sessions.filter(
          (session) => initialSessionsValues[0].id === session.id
        );
      }

      if (initialSessionsValues[1]) {
        if (
          Array.isArray(initialSessionsValues[1]) &&
          initialSessionsValues[1].length > 0
        ) {
          pickedSecondSession = sessions.filter(
            (session) => initialSessionsValues[1][0].id === session.id
          );
        } else {
          pickedSecondSession = sessions.filter(
            (session) => initialSessionsValues[1].id === session.id
          );
        }
      }

      if (initialSessionsValues[2]) {
        if (
          Array.isArray(initialSessionsValues[2]) &&
          initialSessionsValues[2].length > 0
        ) {
          pickedThirdSession = sessions.filter(
            (session) => initialSessionsValues[2][0].id === session.id
          );
        } else {
          pickedThirdSession = sessions.filter(
            (session) => initialSessionsValues[2].id === session.id
          );
        }
      }

      if (pickedFirstSession && pickedFirstSession[0]?.id) {
        setSession1(pickedFirstSession[0]);
        fetchSlots(pickedFirstSession[0].id, "session1");
      }
      if (pickedSecondSession && pickedSecondSession[0]?.id) {
        setSession2(pickedSecondSession[0]);
        fetchSlots(pickedSecondSession[0].id, "session2");
      }
      if (pickedThirdSession && pickedThirdSession[0]?.id) {
        setSession3(pickedThirdSession[0]);
        fetchSlots(pickedThirdSession[0].id, "session3");
      }
    }
  }, [sessions]);

  useEffect(() => {
    const res = [];
    if (session1?.id) {
      const pref = 1;

      if (session1.slots?.length) {
        const selectedSlots = session1.slots?.filter(
          (el) => el.isSelected === true
        );
        if (selectedSlots.length > 0) {
          selectedSlots.forEach((s) => {
            res.push(mapSlot(s, session1, pref));
          });
        } else {
          res.push(mapSlot(null, session1, pref));
        }
      }
    }
    if (session2?.id) {
      const pref = 2;

      if (session2.slots?.length) {
        const selectedSlots = session2.slots?.filter(
          (el) => el.isSelected === true
        );
        if (selectedSlots.length > 0) {
          selectedSlots.forEach((s) => {
            res.push(mapSlot(s, session2, pref));
          });
        } else {
          res.push(mapSlot(null, session2, pref));
        }
      }
    }

    if (session3?.id) {
      const pref = 3;
      if (session3.slots?.length) {
        const selectedSlots = session3.slots?.filter(
          (el) => el.isSelected === true
        );
        if (selectedSlots.length > 0) {
          selectedSlots.forEach((s) => {
            res.push(mapSlot(s, session3, pref));
          });
        } else {
          res.push(mapSlot(null, session3, pref));
        }
      }
    }
    props.input.onChange(res);
  }, [session1, session2, session3]);

  const mapSlot = (slot, session, preference) => {
    let time = "";
    let d1 = getDateLabel(session.fromDate);
    let d2 = getDateLabel(session.toDate);
    if (slot) {
      const slotTimeStr = slot.fromTimeResolved
        ? `${slot.fromTimeResolved} - ${slot.toTimeResolved}`
        : "";
      time = slot.fromTimeResolved ? `${d1} - ${d2} ${slotTimeStr}` : null;
    } else {
      time = `${d1} - ${d2}`;
    }
    return {
      id: session.id,
      sessiontime: time,
      sessionname: `${d1} - ${d2} ${session.location}`,
      location: session.location,
      preference: preference,
      sessTimeId: slot ? slot.id : null,
      notes: slot ? slot.notes : null,
    };
  };

  const getDateLabel = (dateStr) => {
    if (dateStr === null) {
      return "Jan 01";
    }
    return moment(dateStr).format("MMM DD");
  };

  const checkIsSelected = (id) => {
    return session1?.id === id || session2?.id === id || session3?.id === id;
  };

  const valueChange = (e) => {
    ++sessionChangeCounter;
    const key = e.target.name;
    const id = e.target.value;
    const session = { ...sessions.find((el) => String(el.id) === id) };
    if (session.id) {
      if (key === "session1") {
        session.slots = [];

        setSession1(session);
      }
      if (key === "session2") {
        session.slots = [];
        setSession2(session);
      }
      if (key === "session3") {
        session.slots = [];
        setSession3(session);
      }
      fetchSlots(id, key);
    } else {
      if (key === "session1") {
        setSession1(null);
      }
      if (key === "session2") {
        setSession2(null);
      }
      if (key === "session3") {
        setSession3(null);
      }
    }
  };

  const fetchSlots = (id, key) => {
    axios
      .get(`/public/sessions/${id}/session-times`)
      .then((r) => {
        if (key === "session1") {
          setSession1((prev) => {
            let initialSessionsValues = props.input.value;

            if (initialSessionsValues?.length) {
              let theFirstSession = initialSessionsValues[0];
              let theSecondSession;
              let theThirdSession;

              for (let i = 0; i < initialSessionsValues.length; ++i) {
                if (initialSessionsValues[i].id !== theFirstSession.id) {
                  if (
                    theSecondSession &&
                    initialSessionsValues[i].id !== theSecondSession.id
                  ) {
                    theThirdSession = initialSessionsValues[i];
                  } else {
                    theSecondSession = initialSessionsValues[i];
                  }
                }
              }

              let firsSessionSlots = [];
              let secondSessionSlots = [];
              let thirdSessionSlots = [];
              initialSessionsValues.forEach((slot) => {
                if (slot.id === theFirstSession.id) {
                  firsSessionSlots.push(slot);
                }
                if (theSecondSession && slot.id === theSecondSession.id) {
                  secondSessionSlots.push(slot);
                }
                if (theThirdSession && slot.id === theThirdSession.id) {
                  thirdSessionSlots.push(slot);
                }
              });

              initialSessionsValues = [
                firsSessionSlots,
                secondSessionSlots,
                thirdSessionSlots,
              ];

              initialSessionsValues.forEach((item) => {
                if (item === undefined || item.length === 0) {
                  initialSessionsValues.splice(
                    initialSessionsValues.indexOf(item),
                    1
                  );
                }
              });
            }

            if (initialSessionsValues?.length) {
              let pickedValue = Object.assign({}, prev, { slots: r.data });
              let initialValuesSessionTimes = [];
              if (Array.isArray(initialSessionsValues[0])) {
                initialSessionsValues[0].forEach((session) => {
                  if (Array.isArray(session)) {
                    session.forEach((elem) => {
                      initialValuesSessionTimes.push(
                        elem.sessiontime.split(" ").splice(5, 6).join("")
                      );
                    });
                  } else if (!session.sessiontime && session.notes) {
                    initialValuesSessionTimes.push(session.notes);
                  } else {
                    session.sessiontime &&
                      initialValuesSessionTimes.push(
                        session.sessiontime.split(" ").splice(5, 6).join("")
                      );
                  }
                });
              } else {
                initialValuesSessionTimes.push(
                  initialSessionsValues[0].sessiontime
                    .split(" ")
                    .splice(5, 6)
                    .join("")
                );
              }
              pickedValue.slots.forEach((slot) => {
                if (!slot.fromTimeResolved && slot.fromTime) {
                  const array = slot.fromTime.split(":");
                  array.splice(array.indexOf("00"), 1);
                  slot.fromTimeResolved = array.join(":");
                }
                if (
                  (slot.fromTimeResolved &&
                    initialValuesSessionTimes.includes(
                      slot.fromTimeResolved.split(" ").join("") +
                        "-" +
                        slot.toTimeResolved.split(" ").join("")
                    ) &&
                    initialSessionsValues.flat(Infinity)[0].preference === 1 &&
                    sessionChangeCounter < 1) ||
                  (!slot.fromTimeResolved &&
                    initialValuesSessionTimes.includes(slot.notes) &&
                    sessionChangeCounter < 1)
                ) {
                  slot.isSelected = true;
                }
              });
              return pickedValue;
            } else {
              return Object.assign({}, prev, { slots: r.data });
            }
          });
        }
        if (key === "session2") {
          setSession2((prev) => {
            let initialSessionsValues = props.input.value;
            if (initialSessionsValues?.length) {
              let theFirstSession = initialSessionsValues[0];
              let theSecondSession;
              let theThirdSession;

              for (let i = 0; i < initialSessionsValues.length; ++i) {
                if (initialSessionsValues[i].id !== theFirstSession.id) {
                  if (
                    theSecondSession &&
                    initialSessionsValues[i].id !== theSecondSession.id
                  ) {
                    theThirdSession = initialSessionsValues[i];
                  } else {
                    theSecondSession = initialSessionsValues[i];
                  }
                }
              }

              let firsSessionSlots = [];
              let secondSessionSlots = [];
              let thirdSessionSlots = [];

              initialSessionsValues.forEach((slot) => {
                if (slot.id === theFirstSession.id) {
                  firsSessionSlots.push(slot);
                }
                if (theSecondSession && slot.id === theSecondSession.id) {
                  secondSessionSlots.push(slot);
                }
                if (theThirdSession && slot.id === theThirdSession.id) {
                  thirdSessionSlots.push(slot);
                }
              });

              initialSessionsValues = [
                firsSessionSlots,
                secondSessionSlots,
                thirdSessionSlots,
              ];

              initialSessionsValues.forEach((item) => {
                if (item === undefined || item.length === 0) {
                  initialSessionsValues.splice(
                    initialSessionsValues.indexOf(item),
                    1
                  );
                }
              });
            }

            if (initialSessionsValues[1]) {
              let pickedValue = Object.assign({}, prev, { slots: r.data });
              let initialValuesSessionTimes = [];
              if (Array.isArray(initialSessionsValues[1])) {
                initialSessionsValues[1].forEach((session) => {
                  if (session.sessiontime) {
                    initialValuesSessionTimes.push(
                      session.sessiontime.split(" ").splice(5, 6).join("")
                    );
                  } else if (!session.sessiontime && session.notes) {
                    initialValuesSessionTimes.push(session.notes);
                  }
                });
              } else {
                initialValuesSessionTimes.push(
                  initialSessionsValues[1].sessiontime
                    .split(" ")
                    .splice(5, 6)
                    .join("")
                );
              }
              pickedValue.slots.forEach((slot) => {
                if (!slot.fromTimeResolved && slot.fromTime) {
                  const array = slot.fromTime.split(":");
                  array.splice(array.indexOf("00"), 1);
                  slot.fromTimeResolved = array.join(":");
                }
                if (
                  (slot.fromTimeResolved &&
                    initialValuesSessionTimes.includes(
                      slot.fromTimeResolved.split(" ").join("") +
                        "-" +
                        slot.toTimeResolved.split(" ").join("")
                    ) &&
                    sessionChangeCounter < 1) ||
                  (!slot.fromTimeResolved &&
                    initialValuesSessionTimes.includes(slot.notes) &&
                    sessionChangeCounter < 1)
                ) {
                  slot.isSelected = true;
                }
              });
              return pickedValue;
            } else {
              return Object.assign({}, prev, { slots: r.data });
            }
          });
        }
        if (key === "session3") {
          setSession3((prev) => {
            let initialSessionsValues = props.input.value;
            if (initialSessionsValues?.length) {
              let theFirstSession = initialSessionsValues[0];
              let theSecondSession;
              let theThirdSession;

              for (let i = 0; i < initialSessionsValues.length; ++i) {
                if (initialSessionsValues[i].id !== theFirstSession.id) {
                  if (
                    theSecondSession &&
                    initialSessionsValues[i].id !== theSecondSession.id
                  ) {
                    theThirdSession = initialSessionsValues[i];
                  } else {
                    theSecondSession = initialSessionsValues[i];
                  }
                }
              }

              let firsSessionSlots = [];
              let secondSessionSlots = [];
              let thirdSessionSlots = [];
              initialSessionsValues.forEach((slot) => {
                if (slot.id === theFirstSession.id) {
                  firsSessionSlots.push(slot);
                }
                if (theSecondSession && slot.id === theSecondSession.id) {
                  secondSessionSlots.push(slot);
                }
                if (theThirdSession && slot.id === theThirdSession.id) {
                  thirdSessionSlots.push(slot);
                }
              });

              initialSessionsValues = [
                firsSessionSlots,
                secondSessionSlots,
                thirdSessionSlots,
              ];

              initialSessionsValues.forEach((item) => {
                if (item === undefined || item.length === 0) {
                  initialSessionsValues.splice(
                    initialSessionsValues.indexOf(item),
                    1
                  );
                }
              });
            }
            if (initialSessionsValues[2]) {
              let pickedValue = Object.assign({}, prev, { slots: r.data });
              let initialValuesSessionTimes = [];
              if (Array.isArray(initialSessionsValues[2])) {
                initialSessionsValues[2].forEach((session) => {
                  if (session.sessiontime) {
                    initialValuesSessionTimes.push(
                      session.sessiontime.split(" ").splice(5, 6).join("")
                    );
                  } else if (!session.sessiontime && session.notes) {
                    initialValuesSessionTimes.push(session.notes);
                  }
                });
              } else {
                initialValuesSessionTimes.push(
                  initialSessionsValues[2].sessiontime
                    .split(" ")
                    .splice(5, 6)
                    .join("")
                );
              }
              pickedValue.slots.forEach((slot) => {
                if (!slot.fromTimeResolved && slot.fromTime) {
                  const array = slot.fromTime.split(":");
                  array.splice(array.indexOf("00"), 1);
                  slot.fromTimeResolved = array.join(":");
                }
                if (
                  (slot.fromTimeResolved &&
                    initialValuesSessionTimes.includes(
                      slot.fromTimeResolved.split(" ").join("") +
                        "-" +
                        slot.toTimeResolved.split(" ").join("")
                    ) &&
                    sessionChangeCounter < 1) ||
                  (!slot.fromTimeResolved &&
                    initialValuesSessionTimes.includes(slot.notes) &&
                    sessionChangeCounter < 1)
                ) {
                  slot.isSelected = true;
                }
              });
              return pickedValue;
            } else {
              return Object.assign({}, prev, { slots: r.data });
            }
          });
        }
      })
      .catch();
  };

  const countSelected = (session) => {
    let counter = 0;
    for (let slot of session.slots) {
      if (slot.isSelected) {
        ++counter;
      }
    }
    return counter;
  };

  // const sortTimes = (arr) => {
  //   return arr.slice().sort(function (a, b) {
  //     return (
  //       new Date("1970/01/01 " + a.fromTimeResolved) -
  //       new Date("1970/01/01 " + b.fromTimeResolved)
  //     );
  //   });
  // };

  const sortTimes = (arr) => {
    let fullSlots = [];
    let sortedDates = arr.slice().sort(function (a, b) {
      return (
        new Date("1970/01/01 " + a.fromTimeResolved) -
        new Date("1970/01/01 " + b.fromTimeResolved)
      );
    });

    sortedDates.forEach((date) => {
      if (isNotAvailableSlot(date)) {
        fullSlots.push(date);
      }
    });

    fullSlots.forEach((fullSlot) => {
      sortedDates.splice(sortedDates.indexOf(fullSlot), 1);
    });

    sortedDates.push.apply(sortedDates, fullSlots);

    return sortedDates;
  };

  const isNotAvailableSlot = (slot) => {
    return slot.registered >= slot.maxStudent || slot.maxStudent === 0;
  };

  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const sortSessions = (arr) => {
    for (let item of arr) {
      if (item.fromDate === null) {
        arr.splice(arr.indexOf(item), 1);
        arr.unshift(item);
      }
    }

    sessions = arr;
  };

  sortSessions(sessions);

  const formatDateDay = (o) => {
    let day = String(new Date(o.fromDate).getDate());
    if (day.length === 1) {
      day = "0" + day;
    }
    return day;
  };

  const formatEndDateDay = (o) => {
    let day = String(new Date(o.toDate).getDate());
    if (day.length === 1) {
      day = "0" + day;
    }
    return day;
  };

  sessions = sessions.filter(function (item, pos) {
    return sessions.indexOf(item) == pos;
  });

  sessions.sort(function (a, b) {
    return a.fromDate < b.fromDate ? -1 : a.fromDate > b.fromDate ? 1 : 0;
  });

  return (
    <div className="d-flex row">
      <div className="d-flex flex-column col-lg-4">
        <div className="d-flex flex-column">
          <label>Session Preference #1</label>
          <select name="session1" value={session1?.id} onChange={valueChange}>
            <option className="session-selector-text" value={undefined}>
              -Please select a location-
            </option>
            <option className="session-selector-text text-red" value={null}>
              -Clear-
            </option>
            {sessions.map((o) => {
              if (o.fromDate && o.toDate) {
                return (
                  <option
                    className="session-selector-text"
                    disabled={checkIsSelected(o.id)}
                    key={o.id}
                    value={o.id}
                  >
                    {months[new Date(o.fromDate).getMonth()]}&nbsp;
                    {/* {new Date(o.fromDate).getDate()} */}
                    {formatDateDay(o)}
                    {/* {new Date(o.fromDate).getYear() + Number(1900)} */}
                    &nbsp;-&nbsp;
                    {months[new Date(o.toDate).getMonth()]}&nbsp;
                    {formatEndDateDay(o)};&nbsp;{o.location}
                  </option>
                );
              } else {
                return (
                  <option
                    className="session-selector-text"
                    disabled={checkIsSelected(o.id || o)}
                    key={o.id || o}
                    value={o.id || o}
                  >
                    {o.location || "** " + o + " Classes **"}
                  </option>
                );
              }
            })}
          </select>
          {sessionError1 && (
            <span className={"validation-error mt-2"}>
              At least one choice per preference is required. If a preference no
              longer works for you, select -Clear- from the top of this dropdown
              menu, and leave blank.
            </span>
          )}
        </div>
        {session1?.id && session1.notes && (
          <div className="mt-3 details-wrapper">
            <span className="fw-bold">Comments/Notes:&nbsp;</span>
            <span className="text-red">{session1.notes}</span>
          </div>
        )}

        {session1?.slots?.length > 0 && (
          <div className="mt-3 details-wrapper">
            <div>
              <div>
                <div className="d-flex justify-content-between text-uppercase">
                  <h2 className="text-primary">Times selected</h2>
                  <h2 className="text-primary">({countSelected(session1)})</h2>
                </div>
                {sortTimes(session1.slots)
                  .filter((el) => el.isSelected === true)
                  .map((slot) => (
                    <SlotOption
                      slot={slot}
                      session={session1}
                      setSession={setSession1}
                      isSelected={true}
                    />
                  ))}
              </div>
              <div>
                <div className="d-flex justify-content-between text-uppercase">
                  <h2 className="text-primary">Times not-selected</h2>
                  <h2 className="text-primary">
                    ({session1.slots.length - countSelected(session1)})
                  </h2>
                </div>
                {sortTimes(session1.slots)
                  .filter((el) => !el.isSelected)
                  .map((slot) => (
                    <SlotOption
                      slot={slot}
                      session={session1}
                      setSession={setSession1}
                      isSelected={false}
                    />
                  ))}
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="d-flex flex-column col-lg-4">
        <div className="d-flex flex-column">
          <label>Session Preference #2</label>
          <select name="session2" value={session2?.id} onChange={valueChange}>
            <option className="session-selector-text" value={undefined}>
              -Please select a location-
            </option>
            <option className="session-selector-text text-red" value={null}>
              -Clear-
            </option>
            {sessions.map((o) => {
              if (o.fromDate && o.toDate) {
                return (
                  <option
                    className="session-selector-text"
                    disabled={checkIsSelected(o.id)}
                    key={o.id}
                    value={o.id}
                  >
                    {months[new Date(o.fromDate).getMonth()]}&nbsp;
                    {/* {new Date(o.fromDate).getDate()} */}
                    {formatDateDay(o)}
                    {/* {new Date(o.fromDate).getYear() + Number(1900)} */}
                    &nbsp;-&nbsp;
                    {months[new Date(o.toDate).getMonth()]}&nbsp;
                    {formatEndDateDay(o)};&nbsp;{o.location}
                  </option>
                );
              } else {
                return (
                  <option
                    className="session-selector-text"
                    disabled={checkIsSelected(o.id || o)}
                    key={o.id || o}
                    value={o.id || o}
                  >
                    {o.location || "** " + o + " Classes **"}
                  </option>
                );
              }
            })}
          </select>
          {sessionError2 && (
            <span className={"validation-error mt-2"}>
              At least one choice per preference is required. If a preference no
              longer works for you, select -Clear- from the top of this dropdown
              menu, and leave blank.
            </span>
          )}
        </div>
        {session2?.id && session2.notes && (
          <div className="mt-3 details-wrapper">
            <span className="fw-bold">Comments/Notes:&nbsp;</span>
            <span className="text-red">{session2.notes}</span>
          </div>
        )}

        {session2?.slots?.length > 0 && (
          <div className="mt-3 details-wrapper">
            <div>
              <div>
                <div className="d-flex justify-content-between text-uppercase">
                  <h2 className="text-primary">Times selected</h2>
                  <h2 className="text-primary">({countSelected(session2)})</h2>
                </div>
                {sortTimes(session2.slots)
                  .filter((el) => el.isSelected === true)
                  .map((slot) => (
                    <SlotOption
                      slot={slot}
                      session={session2}
                      setSession={setSession2}
                      isSelected={true}
                    />
                  ))}
              </div>
              <div>
                <div className="d-flex justify-content-between text-uppercase">
                  <h2 className="text-primary">Times not-selected</h2>
                  <h2 className="text-primary">
                    ({session2.slots.length - countSelected(session2)})
                  </h2>
                </div>
                {sortTimes(session2.slots)
                  .filter((el) => !el.isSelected)
                  .map((slot) => (
                    <SlotOption
                      slot={slot}
                      session={session2}
                      setSession={setSession2}
                      isSelected={false}
                    />
                  ))}
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="d-flex flex-column col-lg-4">
        <div className="d-flex flex-column">
          <label>Session Preference #3</label>
          <select name="session3" value={session3?.id} onChange={valueChange}>
            <option className="session-selector-text" value={undefined}>
              -Please select a location-
            </option>
            <option className="session-selector-text text-red" value={null}>
              -Clear-
            </option>
            {sessions.map((o) => {
              if (o.fromDate && o.toDate) {
                return (
                  <option
                    className="session-selector-text"
                    disabled={checkIsSelected(o.id)}
                    key={o.id}
                    value={o.id}
                  >
                    {months[new Date(o.fromDate).getMonth()]}&nbsp;
                    {/* {new Date(o.fromDate).getDate()} */}
                    {formatDateDay(o)}
                    {/* {new Date(o.fromDate).getYear() + Number(1900)} */}
                    &nbsp;-&nbsp;
                    {months[new Date(o.toDate).getMonth()]}&nbsp;
                    {formatEndDateDay(o)};&nbsp;{o.location}
                  </option>
                );
              } else {
                return (
                  <option
                    className="session-selector-text"
                    disabled={checkIsSelected(o.id || o)}
                    key={o.id || o}
                    value={o.id || o}
                  >
                    {o.location || "** " + o + " Classes **"}
                  </option>
                );
              }
            })}
          </select>
          {sessionError3 && (
            <span className={"validation-error mt-2"}>
              At least one choice per preference is required. If a preference no
              longer works for you, select -Clear- from the top of this dropdown
              menu, and leave blank.
            </span>
          )}
        </div>
        {session3?.id && session3.notes && (
          <div className="mt-3 details-wrapper">
            <span className="fw-bold">Comments/Notes:&nbsp;</span>
            <span className="text-red">{session3.notes}</span>
          </div>
        )}

        {session3?.slots?.length > 0 && (
          <div className="mt-3 details-wrapper">
            <div>
              <div>
                <div className="d-flex justify-content-between text-uppercase">
                  <h2 className="text-primary">Times selected</h2>
                  <h2 className="text-primary">({countSelected(session3)})</h2>
                </div>
                {sortTimes(session3.slots)
                  .filter((el) => el.isSelected === true)
                  .map((slot) => (
                    <SlotOption
                      slot={slot}
                      session={session3}
                      setSession={setSession3}
                      isSelected={true}
                    />
                  ))}
              </div>
              <div>
                <div className="d-flex justify-content-between text-uppercase">
                  <h2 className="text-primary">Times not-selected</h2>
                  <h2 className="text-primary">
                    ({session3.slots.length - countSelected(session3)})
                  </h2>
                </div>
                {sortTimes(session3.slots)
                  .filter((el) => !el.isSelected)
                  .map((slot) => (
                    <SlotOption
                      slot={slot}
                      session={session3}
                      setSession={setSession3}
                      isSelected={false}
                    />
                  ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SessionField;
