module.exports = {
  AUTH_TOKEN_KEY: 'auth_tkn',
  GOOGLE_MAP_KEY: '',
  GOOGLE_MAP_ID: '',
  DATE_FORMAT_API: 'yyyy-MM-DD',
  DATE_FORMAT_UI: 'MM/DD/YYYY',
  FORM_KEY: 'reserve',
  RADIO_BOOLEAN_KEYS: {
    swamWithMe: 'swamWithMe',
    returningStudent: 'returningStudent',
    hostContacted: 'hostContacted',
    eightDayBrushup: 'eightDayBrushup'
  }
};
