import React, { useEffect } from "react";
import FieldWrapper from "../../../shared/form-fields/FieldWrapper";
import { Field } from "react-final-form";
import { agreementValidation, Error, scrollSelect } from "../FormService";
import AgreementField from "../../../shared/form-fields/fields/agreement/AgreementField";
import "./Agreement.scss";
import ReservationSteps from "../reservation-steps/ReservationSteps";
import { store, useGlobalState } from "state-pool";

const Agreement = () => {
  return (
    <>
      <div id="initial-scroll-point-step-3"></div>
      <div className="d-flex flex-column form-page agreement">
        <div id="error-scroll-point-step-3"></div>
        <ReservationSteps step3="active" />
        <div className="row mt-5">
          <h2 className="col-12 text-center">
            RESERVE YOUR SESSION: STEP 3 OF 3
          </h2>
        </div>
        <div className="row agreement__title-wrapper">
          <div className="col-2"></div>
          <div className="col text-center agreement__title">
            WAIVER / RELEASE OF LIABILITY. PLEASE READ CAREFULLY BEFORE SIGNING.
            THIS IS A RELEASE OF LIABILITY AND WAIVER OF CERTAIN LEGAL RIGHTS.:
          </div>
          <div className="col-2"></div>
        </div>
        <div className="d-flex field-group row-cols-1">
          <FieldWrapper>
            <Field name="agreement" component={AgreementField}></Field>
          </FieldWrapper>
        </div>
      </div>
    </>
  );
};
export default Agreement;
