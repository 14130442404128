import React, { useEffect, useState } from "react";

import ReserveFormWizard from "./ReserveFormWizard";
import PersonalDetails from "./step-1/PersonalDetails";
import ChooseTime from "./step-2/ChooseTime";
import Agreement from "./step-3/Agreement";
import ConfirmationStep from "./step-4/ConfirmationStep";
import { FORM_KEY, RADIO_BOOLEAN_KEYS } from "../../constants/constants";
import axios from "../../http/axios";

import { store, useGlobalState } from "state-pool";

const ReserveForm = () => {
  const [initialValues, setInitialValues] = useState(undefined);
  const [sessions, setSessions] = useState([]);
  const [recaptcha, setRecaptcha] = useState([]);
  const [warningPopup, setWarningPopup] = useState([]);
  const [isValidEarlyBird, setEralyBirdStatus] = useState(false);
  const [earlyBirdKey, setEralyBirdKey] = useState("");
  const [showReminder, setShowReminder] = useState(undefined);
  const [activeConfirmationText, setActiveConfirmationText] = useState([]);

  const [
    clearSecondStepForm,
    setClearSecondStepForm,
    updateClearSecondStepForm,
  ] = useGlobalState("clearSecondStepForm");

  useEffect(() => {
    const searchParams = new URLSearchParams(document.location.search);
    const earlyBirdKey = searchParams.get("earlybird");
    setEralyBirdKey(earlyBirdKey);

    axios
      .get("/recaptcha-settings")
      .then((r) => {
        setRecaptcha(r.data);
      })
      .catch((err) => {});

    axios
      .get("/global-settings/show-popup-reminder")
      .then((r) => {
        setShowReminder(r.data?.value === "true");
      })
      .catch((err) => {});

    axios
      .get("/early-bird")
      .then((r) => {
        setWarningPopup(r.data);
      })
      .catch(() => {});

    if (earlyBirdKey) {
      axios
        .get(`/public/sessions?earlyBirdKey=${earlyBirdKey}`)
        .then((r) => {
          setSessions(r.data);
        })
        .catch((err) => {});
      axios
        .get(`/early-bird/is-valid-key?key=${earlyBirdKey}`)
        .then((r) => {
          setEralyBirdStatus(r.data);
        })
        .catch(() => {});
    } else {
      axios
        .get("/public/sessions")
        .then((r) => {
          setSessions(r.data);
        })
        .catch((err) => {});
      setEralyBirdStatus(false);
    }

    axios
      .get("/public/confirmation-texts/active")
      .then((r) => {
        if (r.data) {
          setActiveConfirmationText(r.data ?? null);
        } else {
          setActiveConfirmationText(null);
        }
      })
      .catch((_) => {
        setActiveConfirmationText(null);
      });
  }, []);

  useEffect(() => {
    const saved = sessionStorage.getItem(FORM_KEY);
    if (saved) {
      try {
        const parsed = JSON.parse(saved);
        setInitialValues(parsed);
      } catch (e) {
        setInitialValues({ children: [{}] });
      }
    } else {
      setInitialValues({ children: [{}] });
    }
  }, []);

  const onSubmit = (values) => {
    let body = {
      earlyBirdKey: earlyBirdKey,
    };
    const radioKeys = Object.keys(RADIO_BOOLEAN_KEYS);
    Object.keys(values).forEach((key) => {
      const val = values[key];
      if (radioKeys.includes(key)) {
        body[key] = val === "true";
      } else if (key === "children") {
        body[key] = val.map((student) => {
          const childBody = {};
          Object.keys(student).forEach((key) => {
            const val = student[key];
            if (radioKeys.includes(key)) {
              childBody[key] = val === "true";
            } else {
              childBody[key] = val;
            }
          });
          return childBody;
        });
      } else {
        body[key] = val;
      }
    });

    body.children.forEach((child) => {
      child.birthday = new Date(child.birthday);
    });
    delete body.agreementCheck;
    delete body.emailVerify;
    return axios.post("/reservations", body);
  };

  return (
    <div className="register-page">
      <div className="content-block container mt-5">
        {initialValues !== undefined && (
          <ReserveFormWizard
            clearSecondStepForm={clearSecondStepForm}
            initialValues={initialValues}
            onSubmit={onSubmit}
            recaptcha={recaptcha}
            showReminder={showReminder}
            warningPopup={warningPopup}
            isValidEarlyBird={isValidEarlyBird}
            warningPopupEnabled={warningPopup.enabled}
          >
            <ReserveFormWizard.Page>
              <PersonalDetails />
            </ReserveFormWizard.Page>
            <ReserveFormWizard.Page>
              <ChooseTime sessions={sessions} />
            </ReserveFormWizard.Page>
            <ReserveFormWizard.Page>
              <Agreement />
            </ReserveFormWizard.Page>
            <ReserveFormWizard.Page>
              <ConfirmationStep
                activeConfirmationText={activeConfirmationText}
              />
            </ReserveFormWizard.Page>
          </ReserveFormWizard>
        )}
      </div>
    </div>
  );
};

export default ReserveForm;
