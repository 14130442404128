import React, { useState } from "react";
import "./ReservationSteps.scss";

import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ReservationSteps = ({ step1, step2, step3, step4 }) => {
  return (
    <>
      <div className="reservation-steps">
        <div className="row">
          <div
            className={`col-lg-3 col-md-6 d-flex justify-content-center align-items-center gap-2 step mt-2 ${step1}`}
          >
            &#40;1&#41; PERSONAL DETAILS <FontAwesomeIcon icon={faArrowRight} />
          </div>
          <div
            className={`col-lg-3 col-md-6 d-flex justify-content-center align-items-center gap-2 step mt-2 ${step2}`}
          >
            &#40;2&#41; CHOOSE TIME <FontAwesomeIcon icon={faArrowRight} />
          </div>
          <div
            className={`col-lg-3 col-md-6 d-flex justify-content-center align-items-center gap-2 step mt-2 ${step3}`}
          >
            &#40;3&#41; AGREEMENT <FontAwesomeIcon icon={faArrowRight} />
          </div>
          <div
            className={`col-lg-3 col-md-6 d-flex justify-content-center align-items-center gap-2 step mt-2 ${step4}`}
          >
            &#40;4&#41; CONFIRMATION
          </div>
        </div>
      </div>
    </>
  );
};

export default ReservationSteps;
