import React from 'react';

const FieldWrapper = ({children}) => {
    return (
        <div className="d-flex flex-column field-control">
            {children}
        </div>
    )
}
export default FieldWrapper;
