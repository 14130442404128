import React, { useEffect, useState } from "react";

import ReservationSteps from "../reservation-steps/ReservationSteps";

const ConfirmationStep = (props) => {
  let [activeConfirmationText, setActiveConfirmationText] = useState([]);

  useEffect(() => {
    if (props.activeConfirmationText) {
      setActiveConfirmationText(props.activeConfirmationText);
    } else {
      setActiveConfirmationText(null);
    }
  }, []);

  return (
    <div className="d-flex flex-column confirmation-page">
      <ReservationSteps step4="active" />
      <div className="row mt-6 mb-10">
        {activeConfirmationText != null && (
          <div
            className="custom-confirmation-message"
            dangerouslySetInnerHTML={{
              __html: activeConfirmationText?.content?.toString(),
            }}
          ></div>
        )}
        {activeConfirmationText == null && (
          <h2 className="col-12 text-center сonfirmation-text">
            THANK YOU FOR YOUR SWIM RESERVATION REQUEST.
            <br /> <br />
            A copy of your request has been sent to your email. Please contact
            me asap if you did not receive it. <br />
            <br />
            Also, please add{" "}
            <a
              style={{ textDecoration: "underline" }}
              href="mailto:info@gotbubbles.com"
            >
              info@gotbubbles.com
            </a>{" "}
            to your address book so you don't miss future communications.
            <br />
            <br />I WILL BE IN TOUCH WITH YOU SHORTLY TO CONFIRM YOUR SWIM TIME.
          </h2>
        )}
      </div>
    </div>
  );
};
export default ConfirmationStep;
