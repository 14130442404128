import React from "react";
import PropTypes from "prop-types";
import { Form } from "react-final-form";
import arrayMutators from "final-form-arrays";
import { saveFormToStorage } from "./FormService";
import { FORM_KEY } from "../../constants/constants";

import ReCAPTCHA from "react-google-recaptcha";
import { Button, Modal } from "react-bootstrap";

import ClipLoader from "react-spinners/ClipLoader";
import { scrollToTop } from "../../services/Utils";

const recaptchaRef = React.createRef();
export default class ReserveFormWizard extends React.Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
  };
  static Page = ({ children, mutators }) => {
    if (typeof children === "function") {
      return children(mutators);
    }

    return children;
  };

  constructor(props) {
    let initValues = props.initialValues;
    if (props.clearSecondStepForm) {
      delete initValues.sessions;
      delete initValues.skillLevel;
      delete initValues.comments;
      delete initValues.hearAboutMe;
      delete initValues.hostContacted;
      delete initValues.eightDayBrushup;
    }
    super(props);
    this.state = {
      page: 0,
      values: initValues || {},
      token: null,
      showModal: false,
      pendingValues: null,
      loading: false,
      color: "#09329C",
      invalidCaptcha: false,
      serverError: false,
      warningPopupEnabled: false,
      warningPopupClosed: false,
    };
  }

  handleClose = () => {
    this.setState({ showModal: false });
  };

  handleWarningPopupClose = () => {
    this.setState({
      warningPopupEnabled: false,
      warningPopupClosed: true,
    });
  };

  handleWarningPopupShow = () => {
    this.state.warningPopupEnabled = true;
  };

  handleShow = (values) => {
    this.setState({ pendingValues: values });
    this.setState({ showModal: true });
  };

  next = (values, isLastPage) =>
    this.setState(
      (state) => ({
        page: Math.min(state.page + 1, this.props.children.length - 1),
        values,
      }),
      () => {
        if (!isLastPage) {
          setTimeout(() => {
            scrollToTop(240, "smooth");
          }, 100);
        }
      }
    );

  previous = () =>
    this.setState((state) => ({
      page: Math.max(state.page - 1, 0),
    }));

  /**
   * NOTE: Both validate and handleSubmit switching are implemented
   * here because 🏁 Redux Final Form does not accept changes to those
   * functions once the form has been defined.
   */

  validate = (values) => {
    const activePage = React.Children.toArray(this.props.children)[
      this.state.page
    ];
    return activePage.props.validate ? activePage.props.validate(values) : {};
  };

  handleSubmit = (values) => {
    const { children, onSubmit } = this.props;
    const { page } = this.state;
    const isLastPage = page === React.Children.count(children) - 2;

    const submitForm = (body) => {
      onSubmit(body)
        .then(() => {
          sessionStorage.removeItem(FORM_KEY);
          this.next(values);
        })
        .catch((e) => {
          if (e.response.status === 200) {
            this.setState({
              loading: false,
              invalidCaptcha: false,
              serverError: false,
            });
          } else if (e.response.data === "Invalid captcha") {
            this.setState({
              loading: false,
              invalidCaptcha: true,
              serverError: false,
            });
            this.setState((state) => ({
              page: state.page,
              values,
            }));
          } else if (e.response.status !== 200) {
            this.setState({
              loading: false,
              invalidCaptcha: false,
              serverError: true,
            });
            this.setState((state) => ({
              page: state.page,
              values,
            }));
          }
        });
    };

    const handleSubmissionWithRecaptcha = () => {
      recaptchaRef.current.executeAsync().then((res) => {
        if (!this.state.token || !res) {
          this.setState({ loading: false, invalidCaptcha: true });
          return;
        }
        this.setState({ invalidCaptcha: false });
        const body = { ...values, ...{ recaptchaToken: this.state.token } };
        submitForm(body);
      });
    };

    if (isLastPage) {
      this.setState({ loading: true });
      if (this.props.recaptcha?.enabled) {
        handleSubmissionWithRecaptcha();
      } else {
        submitForm(values);
      }
    } else {
      if (page === 1 && this.props.showReminder) {
        this.handleShow(values);
      } else {
        for (let i = 0; i < values.children.length; ++i) {
          values.children[i].birthday = this.formatDate(
            values.children[i].birthday
          );
        }

        this.goToNextStep(values, isLastPage);
      }
    }
  };

  formatDate = (date) => {
    let formatedDate = "";
    if (date.includes("/")) {
      formatedDate = date.split("/");
      if (formatedDate[0].length < 2) {
        formatedDate[0] = "0" + formatedDate[0];
      }
      if (formatedDate[1].length < 2) {
        formatedDate[1] = "0" + formatedDate[1];
      }
      if (formatedDate[2].length < 4) {
        if (Number("20" + formatedDate[2]) > new Date().getFullYear()) {
          formatedDate[2] = "19" + formatedDate[2];
        } else {
          formatedDate[2] = "20" + formatedDate[2];
        }
      }
    }
    if (date.includes(".")) {
      formatedDate = date.split(".");
      if (formatedDate[0].length < 2) {
        formatedDate[0] = "0" + formatedDate[0];
      }
      if (formatedDate[1].length < 2) {
        formatedDate[1] = "0" + formatedDate[1];
      }
      if (formatedDate[2].length < 4) {
        if (Number("20" + formatedDate[2]) > new Date().getFullYear()) {
          formatedDate[2] = "19" + formatedDate[2];
        } else {
          formatedDate[2] = "20" + formatedDate[2];
        }
      }
    }
    if (date.includes("-")) {
      formatedDate = date.split("-");
      if (formatedDate[0].length < 2) {
        formatedDate[0] = "0" + formatedDate[0];
      }
      if (formatedDate[1].length < 2) {
        formatedDate[1] = "0" + formatedDate[1];
      }

      if (formatedDate[2].length < 4) {
        if (Number("20" + formatedDate[2]) > new Date().getFullYear()) {
          formatedDate[2] = "19" + formatedDate[2];
        } else {
          formatedDate[2] = "20" + formatedDate[2];
        }
      }
    }
    const finalDate = formatedDate.join("/");
    return finalDate;
  };

  onOkModal = (values) => {
    this.handleClose();
    this.goToNextStep(values);
  };

  goToNextStep = (values, isLastPage) => {
    this.next(values, isLastPage);
    saveFormToStorage(values);
  };

  onChangeCaptcha = (e) => {
    this.state.token = e;
  };

  render() {
    const { children } = this.props;
    const { page, values } = this.state;
    const activePage = React.Children.toArray(children)[page];
    const isSubmitPage = page === React.Children.count(children) - 2;
    const isConfirmPage = page === React.Children.count(children) - 1;

    if (
      this.props.warningPopup.enabled &&
      !this.state.warningPopupClosed &&
      this.props.isValidEarlyBird
    ) {
      this.handleWarningPopupShow();
    }

    return (
      <Form
        mutators={{
          ...arrayMutators,
        }}
        initialValues={values}
        validate={this.validate}
        onSubmit={this.handleSubmit}
        render={({
          handleSubmit,
          submitting,
          values,
          pristine,
          invalid,
          form: { mutators },
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <activePage.type {...activePage.props} mutators={mutators} />
              {!isConfirmPage && (
                <>
                  {isSubmitPage && this.state.invalidCaptcha && (
                    <div className="text-danger text-center mt-3">
                      Your reCAPTCHA token has expired or was identified as
                      invalid, please click submission button again
                    </div>
                  )}
                  {isSubmitPage && this.state.serverError && (
                    <div className="text-danger text-center mt-3">
                      Server error
                    </div>
                  )}
                  <div className="reservation-nav-buttons buttons d-flex justify-content-center mt-4 mb-6 gap-3">
                    {page > 0 && (
                      <button type="button" onClick={this.previous}>
                        Previous Step
                      </button>
                    )}
                    {!isSubmitPage && <button type="submit">Next Step</button>}
                    {isSubmitPage && (
                      <div>
                        <button
                          type="submit"
                          disabled={submitting}
                          className="d-flex align-items-center text-center gap-2"
                        >
                          <ClipLoader
                            color={this.state.color}
                            loading={this.state.loading}
                            size={20}
                          />
                          I agree to these terms and I am ready to complete my
                          request.
                        </button>
                      </div>
                    )}
                  </div>
                </>
              )}
              {this.props.recaptcha && this.props.recaptcha?.enabled && (
                <div>
                  <ReCAPTCHA
                    sitekey={this.props.recaptcha.publicKey}
                    onChange={this.onChangeCaptcha}
                    ref={recaptchaRef}
                    size="invisible"
                  />
                </div>
              )}
              <Modal
                show={this.state.showModal}
                onHide={this.handleClose}
                backdrop="static"
                keyboard={false}
                dialogClassName="modal-70w custom-modal"
              >
                <Modal.Header>
                  <Modal.Title>
                    <div
                      className="steps-confirmation-modal__header text-uppercase"
                      role="heading"
                    >
                      Are you sure you selected all the swim times that work for
                      you?
                    </div>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <p>
                    Please note the times shown on the website <b>ARE NOT</b> a
                    live inventory.
                  </p>
                  <p>
                    <b>
                      Requesting multiple swim times <u>PER</u> location is
                      encouraged. You <u>WILL NOT</u> be considered for swim
                      times not specified on your request.
                    </b>
                  </p>
                  <p>
                    If you would like to be considered for more swim times at
                    each location, please go back and update your availability.
                  </p>
                  <div className="reservation-nav-buttons justify-content-start d-flex gap-3">
                    <button type="button" onClick={this.handleClose}>
                      Select More Times
                    </button>
                    <button
                      type="button"
                      onClick={() => this.onOkModal(this.state.pendingValues)}
                    >
                      Next Step
                    </button>
                  </div>
                </Modal.Body>
              </Modal>
              <Modal
                show={this.state.warningPopupEnabled}
                onHide={this.handleWarningPopupClose}
                backdrop="static"
                keyboard={false}
                dialogClassName="modal-70w custom-modal"
              >
                <Modal.Header>
                  <Modal.Title>
                    <div
                      className="steps-confirmation-modal__header text-uppercase"
                      role="heading"
                    >
                      {this.props.warningPopup.subject}
                    </div>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: this.props.warningPopup.body,
                    }}
                  ></div>
                  <div className="reservation-nav-buttons justify-content-start d-flex gap-3">
                    <button
                      type="button"
                      onClick={this.handleWarningPopupClose}
                    >
                      Close
                    </button>
                  </div>
                </Modal.Body>
              </Modal>
            </form>
          );
        }}
      />
    );
  }
}
