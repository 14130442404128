import React from "react";

const Radio = ({input, children, radioId}) => {
    return (
        <div className="form-check">
            <input className="form-check-input cursor-pointer"
                   type="radio" {...input}
                   id={radioId}/>
            <label className="form-check-label cursor-pointer" htmlFor={radioId}>
                {children}
            </label>
        </div>
    );
}
export default Radio
